<template>
  <div v-if="isLoaded">
    <v-card  class="mx-auto my-7" elevation="6"
      :width="$vuetify.breakpoint.mobile ? '80%' : '40%'">
    <ProfileHead :user='charityOfApp'></ProfileHead>
    </v-card>
    <v-card  class="mx-auto my-7" elevation="6"
      :width="$vuetify.breakpoint.mobile ? '80%' : '40%'">
    <ProjectList :isListLoaded.sync='isLoaded'></ProjectList>
    </v-card>
  </div>
  <div v-else>
    <v-skeleton-loader type="card-avatar, list-item"  class="mx-auto my-10"
        max-height="50vh" max-width="60vw"></v-skeleton-loader>
    <v-dialog persistent v-model="redirectNeeded" max-width="80%" >
      <v-card class="pa-8 text-center">
          Please search for a charity to view its projects!
        <v-card-actions>
          <v-btn class="mx-auto mt-7" @click="redirect">Got it</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import ProfileHead from '@/components/account/ProfileHead.vue'
import ProjectList from '@/components/kanban/ProjectList.vue'

export default {
  components: {
    ProfileHead, ProjectList
  },
  data () {
    return {
      isLoaded: false,
      isListLoaded: null,
      redirectNeeded: false
    }
  },
  computed: {
    ...mapGetters('appStore', ['charityOfApp'])
  },
  methods: {
    redirect () {
      const firstSeg = Vue.$pathConverter.getFirstSegment()
      console.error('this page is only available for specific charity app')
      this.$router.push({
        name: 'SearchView',
        params: { root: firstSeg }
      })
    }
  },
  watch: {
    /**
     * This function is triggered when there is a change to charityOfApp. This is used to tackle the issue of network speed.
     * If user's network is not fast enough and the page is loaded before
     *  the charity of the App (function in /router/index.js) is loaded,
     *  then this function will be triggered after the data is loaded to update.
     * When this component is first loaded, watch function will not be triggered but the created function
     * If a charity is found, it is loaded. If not, the user is redirected to error page.
     */
    charityOfApp (newValue) {
      // console.log('CharityAppHomeView: found a new ', newValue)
      if (!('email' in newValue)) {
        const firstSeg = Vue.$pathConverter.getFirstSegment()
        console.error(`Opps! 
          Loading projects fails due to unfound charity. Route to error page of ${firstSeg}`)
        this.$router.push({
          name: 'Error',
          params: { root: firstSeg }
        })
      } else {
        this.isLoaded = true
      }
    }
  },
  /**
   * Determine initial state of loading
   */
  created () {
    const segment = Vue.$pathConverter.getFirstSegment()
    if ('email' in this.charityOfApp && this.charityOfApp.email === segment) {
      this.isLoaded = true
    } else if (segment === 'iocharity') {
      this.isLoaded = false
      this.redirectNeeded = true
    } else {
      this.isLoaded = false
    }
  }
}
</script>
