<template>
  <div>
    <v-card>
      <v-list v-if="isListLoaded === true">
        <v-card  v-for="project in listProjects" :key="project.projectId"
            color="transparent" elevation="0" @click = "navigate(project.projectId)"
            v-ripple
            :width="$vuetify.breakpoint.mobile?'100%':'70%'" class="mx-auto">
        <v-list-item>
          <v-list-item-title class="text-wrap">{{project.title}}</v-list-item-title>
          <v-list-item-action>
              <v-icon color="primary">mdi-arrow-right-bold-hexagon-outline</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-divider inset ></v-divider>
        </v-card>
      </v-list>
      <v-card-title v-else-if="isListLoaded === false">
        <v-row justify="center">
          <p>No project yet!</p>
        </v-row>
      </v-card-title>
      <div v-else-if="isListLoaded === null">
        <v-skeleton-loader
          type="list-item-three-line@2,list-item-two-line"
          loading
          class="mx-auto"
          max-height="80%"
          max-width="50%"
        ></v-skeleton-loader>
      </div>
      <v-row justify="center">
        <v-btn
          v-if="isAppOwner && isListLoaded !== null"
          fab elevation="7" class="mb-4" @click="isProjectDialogOpenned = true">
          <v-icon color="primary">mdi-plus-box-multiple-outline</v-icon>
        </v-btn>
      </v-row>
    </v-card>
    <div>
      <v-dialog v-model="isProjectDialogOpenned" max-width="80%">
        <v-card class="pa-8 text-center">
          <v-card-title>
              <v-row justify="center">Create a new project</v-row>
          </v-card-title>
          <v-form ref="projectForm">
          <NameField label="Project Title" hint="Give meaningful title for your contributor"
            isApplicable :value.sync="newProject.title">
          </NameField>
          <v-textarea solo :label="`Project Description`" v-model="newProject.description"></v-textarea>
          </v-form>
          <v-card-actions>
            <v-btn class="mx-auto mt-7" @click="createNewProject" :loading="createLoading">Create</v-btn>
          </v-card-actions>
            <v-row justify='center' v-if= "message.displayed">
              <span :class="`text-body-3 text-center ${message.color}--text`">
                <v-icon small :color="message.color">mdi-check-circle</v-icon>
                {{message.content}}
              </span>
            </v-row>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import NameField from '@/components/fields/NameField.vue'
export default {
  props: ['isLoaded'],
  components: { NameField },
  data () {
    return {
      isListLoaded: null,
      isAppOwner: null,
      isProjectDialogOpenned: false,
      createLoading: false,
      message: {
        content: '',
        displayed: false,
        color: 'success'
      },
      newProject: {
        title: '',
        description: '...'
      }
    }
  },
  computed: {
    ...mapGetters('userStore', ['loginConfirm']),
    ...mapGetters('projectStore', ['listProjects'])
  },
  methods: {
    ...mapActions('projectStore', ['requestProjects', 'createProject']),
    createNewProject () {
      if (this.$refs.projectForm.validate()) {
        this.createLoading = true
        this.createProject(this.newProject).then(() => {
          this.message.content = 'A new project is created successfully'
          this.message.displayed = true
          this.message.color = 'success'
          setTimeout(() => {
            this.isProjectDialogOpenned = false
            this.message.displayed = false
            this.createLoading = false
          }, 1000)
        }).catch(() => {
          this.message.content = 'Fail to create a project. Please try later'
          this.message.displayed = true
          this.message.color = 'error'
          this.createLoading = false
        })
      }
    },
    navigate (projectId) {
      this.$router.push({
        name: 'ProjectDetailsView',
        params: {
          root: Vue.$pathConverter.getFirstSegment(),
          projectId: projectId
        }
      })
    }
  },
  watch: {
    loginConfirm (newloginConfirm) {
      // as long as keycloak server is connected and refresh is completed
      if (newloginConfirm) {
        const email = Vue.$keycloak.tokenParsed.email
        const segment = Vue.$pathConverter.getFirstSegment()
        this.isAppOwner = email === segment
      } else if (newloginConfirm === false) {
        this.isAppOwner = false
      } else {
        this.isAppOwner = null
      }
    }
  },
  created () {
    this.requestProjects()
      .then((data) => {
        this.projects = data
        this.$emit('update:isLoaded', true)
        this.isListLoaded = true
      })
      .catch((err) => {
        if (err.response.status === 404) {
          // console.log('404')
          this.$emit('update:isLoaded', false)
          this.isListLoaded = false
        } else {
          const firstSeg = Vue.$pathConverter.getFirstSegment()
          console.error('Error when connect to Server')
          this.$router.push({
            name: 'Error',
            params: { root: firstSeg }
          })
        }
      })
    if (this.loginConfirm) {
      this.isAppOwner = true
    }
  }
}
</script>
